<template>
  <div>
    <img src="../../../public/images/h5_xqy(1)/长电4G38版/常电4G38版@3x_01.jpg" width="100%" height="100%">
    <van-tabs v-model="active" sticky swipeable animated offset-top="46px">
      <van-tab title="产品介绍">
        <img src="../../../public/images/h5_xqy(1)/长电4G38版/长电4G38版@3x_02.jpg" width="100%" height="100%">
      </van-tab>
      <van-tab title="参数规格">
        <img src="../../../public/images/h5_xqy(1)/长电4G38版/长电4G38版@3x_03.jpg" width="100%" height="100%">
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
export default {
  name: "长电4G-38",
  data() {
    return {
      active: 0,
    };
  },
}
</script>

<style scoped>

</style>